<template>
    <v-container fluid>
        <v-form class="custom-form" @submit.prevent="getSearchRecord">
            <v-row dense justify="center">
                <v-col cols="3">
                    <v-row no-gutters>
                        <v-col cols="3">RF By</v-col>
                        <v-col cols="9">
                            <v-combobox
                                dense
                                outlined
                                hide-details
                                @focus="$store.dispatch('pharmacist/getPharmacists')"
                                :loading="$store.getters['pharmacist/loading']"
                                :items="$store.getters['pharmacist/pharmacists']"
                                item-value="id"
                                item-text="display_text"
                                v-model="selectedRfBy"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="3">
                    <v-row no-gutters>
                        <v-col cols="3">Invoice</v-col>
                        <v-col cols="9">
                            <v-combobox
                                dense
                                outlined
                                hide-details
                                :loading="$store.getters['testReceipt/loading']"
                                :items="$store.getters['testReceipt/testReceipts']"
                                item-value="id"
                                item-text="code"
                                v-model="invoice"
                            >
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-col>
                
                <v-col cols="1">
                    <v-btn height="26px" type="submit" dark block color="light-blue darken-2" :loading="isLoading">Search</v-btn>
                </v-col>
            </v-row>

            <v-divider></v-divider>
        </v-form>
     
        <v-row v-if="show && cart.length > 0" justify="center">
            <v-col cols="10" id="cashTransactionRecord">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Test Id</th>
                                <th>Test Name	</th>
                                <th>Price</th>
                                <th>Commission(%)</th>
                                <th>Comm. Amount</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, sl) in cart" :key="sl">
                                <td>{{ sl + 1 }}</td>
                                <td>{{ item.code }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.price }}</td>
                                <td style="width:100px">
                                    <v-form class="custom-form">
                                        <v-text-field
                                            dense
                                            outlined
                                            hide-details
                                            v-model="item.commmission"
                                            @input="calculateTotal(sl)"
                                        ></v-text-field>
                                    </v-form>
                                </td>
                                <td>{{ item.comiAmount }}</td>
                                <td>{{ item.total }}</td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align:right"><strong>Total Comm. Amount:</strong></td>
                                <td>
                                    <strong>{{ cart.reduce((p, c) => {return +p + +c.comiAmount}, 0).toFixed(2) }}</strong>
                                </td>
                                <td>
                                    <v-btn height="26px" type="submit" dark block color="light-blue darken-2" :loading="loading" @click.prevent="saveCommission">Save</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'commission',

    data: () => ({
        selectedRfBy: null,
        isLoading: false,
        loading: false,
        cart: [],
        invoice: null,
        show: false
    }),

    watch: {
        async selectedRfBy(rf) {
            if(rf == undefined) return;
            await this.getTestReceipts();
        },
    },

    methods: {
        getTestReceipts() {
            this.$store.dispatch("testReceipt/getTestReceipts", { rfId: this.selectedRfBy.id, status: 'p' });
        },

        async getSearchRecord() {
            this.cart = [];
            if(this.selectedRfBy == null) {
                this.$store.dispatch("snackbar/error", "Select RF pharmacist");
                return;
            }
            if(this.invoice == null) {
                this.$store.dispatch("snackbar/error", "Select invoice");
                return;
            }

            await this.$store.dispatch('testReceipt/getTestReceipt', {id: this.invoice.id})
            let testReceipt = this.$store.getters['testReceipt/testReceipt']
            if (testReceipt == null) return

            let testDetails = testReceipt.test_receipt_details != null ? testReceipt.test_receipt_details : []
            testDetails.forEach(item => {
                this.cart.push({
                    id: item.test_id, 
                    code: item.test.code, 
                    name: item.test.name, 
                    price: item.test.price,
                    commmission: item.test.commission,
                    comiAmount: (+item.test.price * +item.test.commission / 100).toFixed(2),
                })
           })

           this.cart.map(item => {
                item.total = (+item.price - +item.comiAmount).toFixed(2)
                return item;
           })

           this.show = true;
        },

        calculateTotal(ind) {
            this.cart[ind].comiAmount = (+this.cart[ind].price * +this.cart[ind].commmission / 100).toFixed(2)
            this.cart[ind].total = (+this.cart[ind].price - +this.cart[ind].comiAmount).toFixed(2);
        },
        
        async saveCommission() {
            if(this.cart.length == 0) {
                this.$store.dispatch("snackbar/error", "Select cart is empty");
                return;
            }

            this.loading = true;
            let data = {
                rfId: this.selectedRfBy.id,
                testReceiptId: this.invoice.id,
                cart: this.cart
            }
            let isSuccess = await this.$store.dispatch('testReceipt/saveCommission', data)
            if(isSuccess) {
                this.cart = [];
                this.invoice = null;
                this.selectedRfBy = null;
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        background: #156a47;
        color: #fff;
    }
</style>
